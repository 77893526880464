exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-drops-tsx": () => import("./../../../src/pages/drops.tsx" /* webpackChunkName: "component---src-pages-drops-tsx" */),
  "component---src-pages-duels-arena-tsx": () => import("./../../../src/pages/duels-arena.tsx" /* webpackChunkName: "component---src-pages-duels-arena-tsx" */),
  "component---src-pages-elderglade-tsx": () => import("./../../../src/pages/elderglade.tsx" /* webpackChunkName: "component---src-pages-elderglade-tsx" */),
  "component---src-pages-engines-of-fury-tsx": () => import("./../../../src/pages/engines-of-fury.tsx" /* webpackChunkName: "component---src-pages-engines-of-fury-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quests-tsx": () => import("./../../../src/pages/quests.tsx" /* webpackChunkName: "component---src-pages-quests-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-zkrace-tsx": () => import("./../../../src/pages/zkrace.tsx" /* webpackChunkName: "component---src-pages-zkrace-tsx" */)
}

